import axios from "axios";
import { IdleTimer } from "./idletime";

const customConstants = {
    LOGIN_TIMEOUT: 1000 * 60 * 30, // miliseconds
    IDLE_TIMEOUT: 10, // minutes
    API_URL: (process.env.REACT_APP_API_URL) ? process.env.REACT_APP_API_URL : 'http://169.62.55.86:8098',
    //API_URL: (process.env.REACT_APP_API_URL) ? 'https://4c74-85-107-110-17.ngrok-free.app' : 'https://4c74-85-107-110-17.ngrok-free.app/',
    LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
    BASE_URL: process.env.REACT_APP_BASE_URL,
    AVAILABLERESULTS: ["AGE",
        "LIVENESSAGE",
        "FACEBLACKLISTSCORE",
        "FACELIVENESSFACECONSAVG",
        "FACELIVENESSFACECONSPER",
        "GENDER",
        "PHOTOCHEAT",
        "HOLOGRAM",
        "FACESIMILARITY",
        "FACEIMAGEAI_1",
        "FACEIMAGEAI_2",
        "MRZOCRCROSSSIMILARITY",
        "HOLOGRAMFACE",
        "HOLOGRAMIDSIMILARITY",
        "FACELIVENESS",
        "FACE",
        "HIDDENPHOTO",
        "SIGNATUREPHOTO",
        "GUILLOCHEVERIFICATION",
        "RAINBOWVERIFICATION",
        "MRZNFCSIMILARITY",
        "MRZOCRSIMILARITY",
        "NFCMRZSIMILARITY",
        "NFCOCRFACESIMILARITY",
        "NFCOCRSIMILARITY",
        "DRIVERBARCODEIDMATCH",
        "IDCARDBARCODEIDMATCH",
        "IDDOCIDMATCH",
        "IDDOCNAMEMATCH",
        "IDDOCSURNAMEMATCH",
        "LIVENESSAGE",
        "LIVENESSFACEBLACKLISTSCORE",
        "LIVENESSGENDER",
        "FACELIVENESSGESTURE",
        "MRZBIRTHDATECHECKSUM",
        "MRZEXPDATECHECKSUM",
        "MRZDOCNUMCHECKSUM",
        "MRZEXPDATECHECKSUMVALID",
        "MRZBIRTHDATECHECKSUMVALID",
        "MRZDOCNUMCHECKSUMVALID",
        "HOLOGRAMSERIALSIMILARITY",
        "HOLOGRAMBDATESIMILARITY",
        "HOLOGRAMEDATESIMILARITY",
        "AMLVALIDATION",
        "NVIPROVEIDWITHHOLDER",
        "SECONDARYIDFILE",
        "SCREENATTACK",
        "SIGNATUREPHOTO",
        "OLDIDFRONT",
        "OCRLETTERCASEMATCH",
        "OCRMRZSIMILARITY",
        "OCRNFCFACESIMILARITY",
        "OCRNFCSIMILARITY",
        "OLDIDSECONDARYINFORMATION",
        "FACELIVENESSEYEMOVEMENT",
        "FACESEARCHEXISTENCE",
        "HANDLANDMARKSCORE",
        "HANDLANDMARKTYPEMATCH",
        "FACELIVENESSAI",
        "LWICONLYFRONCARDTYPESIMILARITY",
        "LWICONLYFRONTFACESIMILARITY (A-D)",
        "LWICONLYFRONTOCRSIMILARITY",
        "LWICLIVENESSAI",
        "LWICSELFIEFIRSTIDFACESIMILARITY(A-C)",
        "CHIPDETECTION",
        "PHOTOSHOPCONTROLBACK",
        "PHOTOSHOPCONTROL",
        "PHOTOSHOPCONTROL",
        "LWICONLYFRONTFACESIMILARITY",
        "LWICSELFIEFIRSTIDFACESIMILARITY",
        "FWVLIVENESS",
        "FWVLIVENESSAI",
        "FWVLIVENESSGESTURE",
        "FWVLIVENESSFACECONSAVG",
        "FWVLIVENESSFACECONSPER",
        "FWVFACE",
        "FWVAGE",
        "FWVGENDER",
        "FWVSPEECHMATCH",
        "FWVSPEECHCONSISTENCY",
        "SECURITYOBJECTVERIFICATION"
    ],
    SITE_LOGIN: true, // true for development or customer installation
    PAGE_SIZE: 50,
    REMEMBER_USER: true,
    OTP_TIMEOUT: 180,
    INCLUDE_ASSISTBOX_SDK: true,
    GTM: false,
    availableRoutes: ['/session/sign-in', '/session/forgot-password', '/session/reset-password', '/web-sdk/demo', '/web-sdk/colendi', '/privacy', "/session/mfa", '/session/change-password', '/session/create-password']

};

function redirectSigning() {
    if (!(customConstants.availableRoutes.includes(window.location.pathname))) {

        const hasToken = localStorage.getItem('jwt_token');
        localStorage.removeItem("auth_user");
        localStorage.removeItem("_atoken");
        localStorage.removeItem("_rtoken");
        localStorage.removeItem("grants");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("access_token_expires");

        axios.post(customConstants.API_URL + '/oauth/logout').then(function () {
            if (customConstants.SITE_LOGIN === false) {
                window.location = customConstants.LOGIN_URL
            } else {
                if (hasToken !== null) {
                    window.location = '/session/sign-in?timeout=true';
                } else {
                    window.location = '/session/sign-in';
                }

            }
        })

    }
}

axios.interceptors.request.use(
    async function (config) {
        // Do something before request is sent
        const accessTokenExpires = localStorage.getItem('access_token_expires')
        if (config.url && !config.url.includes('access_token') && accessTokenExpires && +new Date() > accessTokenExpires) {
            const form = new FormData();
            form.append('client_id', localStorage.getItem('client_id'));
            form.append('refresh_token', localStorage.getItem('refresh_token'));
            form.append('grant_type', 'refresh_token');

            const response = (await axios(
                {
                    url: customConstants.API_URL + '/oauth/access_token',
                    method: 'POST',
                    headers: {
                        'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                    },
                    data: form
                }
            ))?.data;

            config.headers.Authorization = `Bearer ${response.access_token}`

            localStorage.setItem('jwt_token', response.access_token);
            localStorage.setItem('refresh_token', response.refresh_token);
            localStorage.setItem('access_token_expires', +new Date() + response.expires_in * 1000);

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.access_token;
        }

        return config
    },
    function (error) {
        // Do something with request error
        // if (error && error.message) {
        //     toast.error(error.message)
        // }
        // dispatch(stopLoading())
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    async function (response) {

        if (response === 'Error: Request failed with status code 401') {
            redirectSigning();
        }
        if (((+new Date() - parseInt(localStorage.getItem('last_update')))) > customConstants.LOGIN_TIMEOUT) {
            localStorage.setItem('last_update', +new Date());
            redirectSigning();
        }

        return response;
    }, function (error) {

        if (error.response.status === 401) {
            redirectSigning();
        }

        return Promise.reject(error);
    });

window.axios = axios;

const timer = new IdleTimer(() => redirectSigning(), 1000 * 60 * customConstants.IDLE_TIMEOUT);
timer.activate();


if (localStorage.getItem('last_update', '') === null) {
    localStorage.setItem('last_update', +new Date());

    setTimeout(function () {
        redirectSigning();
    }, customConstants.LOGIN_TIMEOUT)

} else {
    if (((+new Date() - parseInt(localStorage.getItem('last_update')))) > customConstants.LOGIN_TIMEOUT) {
        localStorage.setItem('last_update', +new Date());
        redirectSigning();
    } else {
        setTimeout(function () {
            redirectSigning();
        }, customConstants.LOGIN_TIMEOUT)
    }
}

export default customConstants;
